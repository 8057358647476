import React, { useEffect, useState } from "react"
import Title from "../components/shared-title"
import loginBg from "../images/login.jpg";
import useStores from "../hooks/use-stores"
import { observer } from "mobx-react";
import { Link, navigate } from "gatsby";
import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import VmButton from "../components/shared-button";
import { isBrowser, Language, NavListByCategory, ProdEnvCheck } from "../constants/options";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { t } from "i18next";

const RegistrationPage = observer(() => {
  const { userStore, rootStore } = useStores();
  const [username, setUsername] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [cPassword, setCPassword] = useState<string>("");

  useEffect(() => {
  }, []);

  const onRegisterAccount = () => {
    if (password !== cPassword) {
      rootStore.notify(t("TWO_PASSWORDS_NOT_MATCH"), "warning");
      return;
    }
  }


  return (
    <>
      <Title pageName={t('STUDENT_REGISTRATION')} />
      <div className="top-0 left-0 right-0 bottom-0 h-screen relative bg-theme">
        <div
          className="absolute top-1/2 left-1/2"
          style={{ transform: 'translate(-50%, -50%)', width: 'fit-content' }}
        >
          {userStore.isAuthed && userStore.userToken !== "" && localStorage.USER_TOKEN ?
            <div className="bg-white p-8 rounded-lg">
              <Typography variant="h6">{t('VALIDATING_USER_CREDENTIAL')}...</Typography>
            </div>
            :
            <div
              className={`relative border border-white overflow-auto shadow-2xl`}
              style={{ background: "rgba(255, 255, 255, 0.3)" }}
            >
              <div className="bg-white m-4 relative">
                <div className="px-4 py-10 border border-l-0">
                  <div className="text-2xl text-theme text-center mb-6">{t('STUDENT_REGISTRATION')}</div>
                  <div className="my-4">
                    <Box marginBottom={2}>
                      <TextField
                        value={username}
                        label={t('USERNAME')}
                        sx={{ width: 400 }}
                        variant="outlined"
                        size="small"
                        onChange={(value) => setUsername(value.target.value)}
                      />
                    </Box>
                    <Box marginBottom={2}>
                      <TextField
                        value={email}
                        label={t('EMAIL')}
                        sx={{ width: 400 }}
                        variant="outlined"
                        size="small"
                        onChange={(value) => setEmail(value.target.value)}
                      />
                    </Box>
                    <Box marginBottom={2}>
                      <TextField
                        value={password}
                        label={t('NEW_PASSWORD')}
                        sx={{ width: 400 }}
                        variant="outlined"
                        type="password"
                        size="small"
                        onChange={(value) => setPassword(value.target.value)}
                      />
                    </Box>
                    <Box marginBottom={2}>
                      <TextField
                        value={cPassword}
                        label={t('CONFIRM_PASSWORD')}
                        sx={{ width: 400 }}
                        variant="outlined"
                        type="password"
                        size="small"
                        onChange={(value) => setCPassword(value.target.value)}
                      />
                    </Box>
                  </div>

                  <div className="text-center mx-4 mt-4">
                    {userStore.loading ? <CircularProgress className="text-theme" />
                      : <VmButton
                        className="bg-theme text-white w-full py-2"
                        style={{ height: 'fit-content' }}
                        onClick={onRegisterAccount}
                      >
                        {t('REGISTER_AN_ACCOUNT')}
                      </VmButton>}
                  </div>
                  <Link to="/">
                    <Typography
                      className="text-theme text-sm"
                      textAlign={"center"}
                      sx={{ textDecoration: "underline", marginTop: 1 }}>
                      {t('BACK_TO_LOGIN')}
                    </Typography>
                  </Link>
                </div>
              </div>
            </div>}
        </div>
      </div>
    </>
  )
})

export default RegistrationPage;
