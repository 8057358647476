import * as React from "react"
import useStores from "../hooks/use-stores"

// markup
const VmButton = ({
  onClick = () => {}, className = "", disabled = false, disabledElevation = false, style = {}, children
}) => {
  return (
    <button
      type="button"
      style={style}
      className={`${disabled && "disabled-button"} ${className} rounded-lg ${!disabledElevation && "shadow-lg"} hover:shadow-sm`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default VmButton;
